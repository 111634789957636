import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/content'
import SEO from '../components/seo'
export const MixesPageTemplate = ({ title, mixList }) => {
  return (
    <section className="section section--mixes">
      <div className="container">
        <h2 className="title">
          {title}
        </h2>
        <ul style={{
          listStyle: "none",
          margin: "0",
          padding: "0"
        }}>
          {mixList.map((mix) => (
            <li style={{margin: "25px 0", padding: "0" }}>
            <iframe width="100%" height="120" src={mix} frameborder="0" ></iframe>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

MixesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  mixList: PropTypes.array
}

const MixPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SEO title="Featured Mixes" keywords={['DJ', 'Samuel', 'Andres', 'Pittsburgh', 'House']} />
      <MixesPageTemplate
        title={post.frontmatter.title}
        mixList={post.frontmatter.mixList}
      />
    </Layout>
  )
}

MixPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MixPage

export const mixPageQuery = graphql`
  query MixPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        mixList
      }
    }
  }
`
